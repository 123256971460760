import * as React from "react"
import { FC, useEffect, useState } from "react"
import * as api from "../api/general"
import { getJson } from "../api/general"
import styled from "styled-components"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Dropdown } from "semantic-ui-react"
import { Fade } from "react-reveal"
import AddressForm, {
  DEFAULT_ADDRESS_STATE,
} from "../social-supermarket/components/form/address/AddressForm"
import AddressType from "../social-supermarket/model/AddressType"
import { rouge } from "../social-supermarket/constants/colors"
import Spinner from "../social-supermarket/components/spinner/Spinner"
import { getConfig } from "../config"
import { DEFAULT_CONTACT_DETAILS_STATE } from "../social-supermarket/components/form/contact-details/ContactDetailsForm"
import ContactDetailsType from "../social-supermarket/model/ContactDetailsType"
import EmailContactDetailsForm from "../social-supermarket/components/form/contact-details/EmailContactDetailsForm"
import { PrimaryButton } from "@social-supermarket/social-supermarket-components"

const OuterContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
`
const Container = styled.div`
  padding: 0 20px;
  padding-bottom: 200px;
  flex: 1;
`
const Title = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
`
const OptionsContainer = styled.div`
  margin-bottom: 30px;
  min-height: 100px;
`
const Image = styled.img`
  width: 400px !important;
`
const Description = styled.div`
  margin-bottom: 20px;
`
const DropdownContainer = styled.div`
  margin-bottom: 10px;
`
const Label = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 0.8em;
  text-transform: uppercase;
`
const AddressContainer = styled.div``
const ThankYou = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Checkbox = styled.label`
  font-size: 0.8em;
  margin: 10px 0;
  display: block;
  user-select: none;
  cursor: pointer;
  text-align: right;
  margin-bottom: 20px;

  input {
    margin-left: 10px;
  }

  a {
    color: #4183c4;
  }
`
const Error = styled.div`
  color: ${rouge};
`

interface Props {}

interface ApiOption {
  ID: number
  record: string
  name: string
  imageSrc: string
}

interface DropdownOption {
  key: string
  text: string
  value: string
  image: { avatar: boolean; src: string }
}

const page: FC<Props> = () => {
  const [contactDetails, setContactDetails] = useState<ContactDetailsType>({
    ...DEFAULT_CONTACT_DETAILS_STATE,
  })
  const [address, setAddress] = useState<AddressType>({ ...DEFAULT_ADDRESS_STATE })
  const [error, setError] = useState<string>("")
  const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
  const [validate, setValidate] = useState<boolean>(false)
  const [optionsMap, setOptionsMap] = useState<{ [key: string]: ApiOption }>(null)
  const [options, setOptions] = useState<DropdownOption[][]>([])
  const [values, setValues] = useState<string[]>([])
  const [acceptsTerms, setAcceptsTerms] = useState(false)

  const hasSelected =
    options.length > 0 && values.length === options.length && values.every(v => !!v)

  useEffect(() => {
    getOptions().then(o => {
      const optionsMap = {}
      setOptions(
        o.map(j => {
          return j.map(i => {
            optionsMap[i.record] = i
            return {
              key: i.record,
              text: i.name,
              value: i.record,
              image: { avatar: false, src: i.imageSrc },
            }
          })
        })
      )
      setOptionsMap(optionsMap)
      setIsOptionsLoading(false)
    })
  }, [])

  const handleChange = i => (param1, param2) => {
    const newValues = [...values]
    newValues[i] = param2.value
    setValues(newValues)
  }

  const updateAddress = (name, value) =>
    setAddress(prev => ({
      ...prev,
      [name]: value,
    }))

  const updateContactDetails = (name, value) =>
    setContactDetails(prev => ({
      ...prev,
      [name]: value,
    }))

  const handleSubmit = async () => {
    if (!AddressForm.isValid(address) || !EmailContactDetailsForm.isValid(contactDetails)) {
      setValidate(true)
      setError("Please enter a valid address & email.")
      return
    }

    setIsSubmitting(true)

    const selections = values.map(value => optionsMap[value])

    const response = await api.post(
      `${getConfig().baseUrl}/wp-json/social-supermarket/v1/corporate-byo/submit`,
      {
        selections,
        address,
        email: contactDetails.email,
      }
    )

    if (response.status === "ERROR") {
      alert("You have already submitted your choice.")
    } else {
      setHasSubmitted(true)
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    console.log(optionsMap)
  }, [optionsMap])

  return <div>Selection closed</div>

  return (
    <Layout>
      <SEO title={"PWC Risk"} description={""} />

      <Fade>
        <OuterContainer>
          <div>
            <Image src="https://data.socialsupermarket.org/wp-content/uploads/2021/08/The-Chocolate-Lover-Gift-Box-by-Social-Supermarket-scaled.jpg" />
          </div>
          <Container>
            <Spinner isLoading={isSubmitting} label={"Submitting..."} />
            <Title>PwC Risk Gifts</Title>
            <Description>
              <p>
                For Mental Health Awareness Week (Monday 9 May to Sunday 15 May 2022), we would like
                to provide you with some options that you can enjoy at your own leisure. We know
                that self-care can happen in many ways, and we hope that you will find six products
                from the lists below to your taste.
              </p>
              <p>
                Choose from a pre-selected range of over 45 social enterprise gifts, from self-care
                essentials from independent brands, who also take care of others by giving back to
                important causes – supporting human trafficking and domestic violence survivors, to
                delicious food and drink supporting fair trade farmers.
              </p>
              <p>
                <b>Please note this page closes at 8pm on Wednesday 18th May.</b>
              </p>
            </Description>

            {!hasSubmitted && (
              <div>
                <OptionsContainer>
                  <Spinner isLoading={isOptionsLoading} isLocal />
                  {options.map((optionList, i) => (
                    <DropdownContainer>
                      <Label>Treat {i + 1}</Label>
                      <Dropdown
                        placeholder="Select Product"
                        fluid
                        selection
                        options={optionList}
                        value={values[i]}
                        onChange={handleChange(i)}
                      />
                    </DropdownContainer>
                  ))}
                </OptionsContainer>

                {hasSelected && (
                  <Fade>
                    <div>
                      <AddressContainer>
                        <Title>Great choices! Where should we send it?</Title>
                        <AddressForm
                          disableCountry={true}
                          validate={validate}
                          state={address}
                          onChange={updateAddress}
                        />
                      </AddressContainer>
                    </div>
                    <div>
                      <Title>Please provide your work email</Title>
                      <EmailContactDetailsForm
                        onChange={updateContactDetails}
                        state={contactDetails}
                        validate={validate}
                      />
                    </div>
                    <Checkbox>
                      I am happy to share my name with PwC to track orders.
                      <input
                        name="acceptsTerms"
                        type="checkbox"
                        checked={acceptsTerms}
                        onChange={() => setAcceptsTerms(!acceptsTerms)}
                      />
                    </Checkbox>
                    <PrimaryButton onClick={handleSubmit} disabled={!acceptsTerms}>
                      Submit
                    </PrimaryButton>
                    {error && <Error>{error}</Error>}
                  </Fade>
                )}
              </div>
            )}

            {hasSubmitted && <ThankYou>Thank you for your selection.</ThankYou>}
          </Container>
        </OuterContainer>
      </Fade>
    </Layout>
  )
}

export const getOptions = async (): Promise<ApiOption[][]> => {
  return getJson(
    "https://data.socialsupermarket.org/wp-json/social-supermarket/v1/corporate-byo/get-options"
  )
}

export default page
